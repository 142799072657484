<div class="p-2 thank-you" mat-dialog-close>
    <img [src]="imageURL" alt="thank-you">
    <div class="p-2">

        <h5 class="text-center">Happy Thanksgiving from<br>Register Play!</h5>
            <p>As we gather around to celebrate Thanksgiving, we want to extend our warmest wishes to you and your loved ones. We are truly thankful for your continued support and the opportunity to be a part of your journey.</p>
            <p>May your Thanksgiving be filled with happiness, good health, and memorable plays!</p>

            <p>Register Play Team</p>


        <!-- Trade Show -->
        <!-- <ng-container *ngIf="!isMobile">
            <h5>A Big Thank You from Register Play Team!</h5>
            <p>We're truly grateful to everyone who took a moment to stop by our booth at the <b>USA Softball Trade Show in Oklahoma City.</b></p>
            <p>Connecting with you and showcasing our software was a highlight for us. Your feedback and insights not only inspire us but drive our commitment to excellence.</p>
            <p>We're excited about the possibility of working together. Until then, stay safe and play on!</p>
            <p>Warm regards,<br>The Register Play Team</p>
        </ng-container>
        <ng-container *ngIf="isMobile">
            <h5>Thank You for Visiting Us!</h5>
            <p>A big shout-out to everyone who stopped by our booth at the <b>USA Softball Trade Show in Oklahoma City.</b> Loved connecting and sharing our software with you!</p>
            <p>Looking forward to teaming up!</p>
            <p>Best,<br>The Register Play Team</p>
        </ng-container> -->
    </div>
</div>
