import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ThankYouComponent } from '../thank-you/thank-you.component';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

@Component({
    selector: 'app-homepage-one',
    templateUrl: './homepage-one.component.html',
    styleUrls: ['./homepage-one.component.scss']
})
export class HomepageOneComponent implements OnInit {

    image = Math.floor(Math.random() * 10) % 2 == 0 ? 'baseball' : 'basketball';
    image2 = Math.floor(Math.random() * 10) % 3 == 0 ? 'glove' : 'football';

    isMobile: boolean = false;
    constructor(public dialog: MatDialog,
        private breakpointObserver: BreakpointObserver,
    ) {
        this.breakpointObserver.observe(['(max-width: 599px)']).subscribe((state: BreakpointState) => {
            this.isMobile = state.matches;
        });
    }

    ngOnInit(): void {
        // date check
        if (new Date() < new Date('2023-11-24')) {
            this.dialog.open(ThankYouComponent, {
                height: this.isMobile ? 'auto' : 'auto',
                maxHeight: this.isMobile ? '90vh' : '70vh',
                width: this.isMobile ? '90%' : '50%',
                maxWidth: this.isMobile ? '90vw' : '70vw',
                data: { isMobile: this.isMobile }
            });
        }
    }
}
