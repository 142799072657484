<app-hometwo-main-banner></app-hometwo-main-banner>

<section class="featured-services-area pt-100">
    <app-featured-services></app-featured-services>
</section>
<section class="promo-video">
    <app-promo-video></app-promo-video>
</section>

<section class="what-we-do-area pb-100">
    <app-what-we-do></app-what-we-do>
</section>
<app-funfacts></app-funfacts>

<section class="partner-area bg-f9f9f9 ptb-70">
    <app-partner></app-partner>
</section>
